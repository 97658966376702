<template>
  <section>
    <div class="d-flex justify-content-end mt-0 mb-1">
      <EditDevicesGroup
        :id="editGroupData.group.id"
        :key="editGroupData.id"
        ref="edit-group-modal"
        :title="editGroupData.group.title"
        :selected-devices="editGroupData.devices"
        @getAllGroups="getAllGroups"
        @getAllDevices="getAllDevices()"
      />
    </div>
    <div>
      <app-collapse
        accordion
        type="margin"
        class="mt-2"
      >
        <b-skeleton-wrapper :loading="devicesGroupLoading">
          <template #loading>
            <b-skeleton width="100%" />
            <b-skeleton width="75%" />
            <b-skeleton width="50%" />
          </template>
          <div
            v-if="allGroups.length === 0"
            class="text-center mt-5 mb-3 font-medium-3 text-black-50"
          >No Device Groups Found</div>
          <DevicesGroupCollapseItem
            v-for="(allGroupItems, index) in allGroups"
            :id="allGroupItems.id"
            :key="index"
            :title="allGroupItems.name"
            icons="TrashIcon"
            @editDevicesGroup="editDevicesGroup($event, allGroupItems.device_has_group)"
            @deleteDevicesGroup="deleteDevicesGroup"
          >
            <div class="heading-elements">
              <b-table
                :items="allGroupItems.device_has_group"
                responsive
                :fields="tableColumns"
                show-empty
              >
                <!-- Column: name -->
                <template
                  #cell(Devices)="data"
                  class="p-3"
                >
                  <span
                    class="text-nowrap"
                  >
                    {{ data.item.device.name }}
                  </span>
                </template>
              </b-table>
            </div>
          </DevicesGroupCollapseItem>
        </b-skeleton-wrapper>
      </app-collapse>
    </div>
  </section>
</template>

<script>
import {
  BTable, VBToggle, BSkeletonWrapper, BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import DevicesGroupCollapseItem from '@/components/devices-group/DevicesGroupCollapseItem.vue'
import EditDevicesGroup from '@/components/devices-group/EditDevicesGroup.vue'
import { confirmDeletion, showErrorMessage, showToast } from '@/common/global/functions'

Vue.directive('ripple', Ripple)

export default {
  components: {
    DevicesGroupCollapseItem,
    EditDevicesGroup,
    AppCollapse,
    BTable,
    BSkeletonWrapper,
    BSkeleton,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      editGroupData: {
        group: {},
        devices: [],
      },
      id: '',
      allGroups: [],
      allDevices: [],
      groupDevicesName: [],
      devicesGroupLoading: false,
      tableColumns: [{ key: 'Devices' }],
      spinners: [],
    }
  },
  mounted() {
    this.getAllGroups()
  },
  methods: {
    async  getAllGroups() {
      try {
        const res = await this.$axios.get('groups')
        const { data: { data } } = res
        this.allGroups = data
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('All Group', message.toString(), 'danger')
        }
      }
    },
    async getAllDevices() {
      this.$emit('getAllDevicesUpdated')
    },
    openDevicesGroup() {
      this.$refs['add-devices-group'].show()
    },
    editDevicesGroup(object, devicesInGroup) {
      this.editGroupData.group = object
      this.editGroupData.devices = devicesInGroup.map(deviceHasGroup => deviceHasGroup.device)
      const self = this
      setTimeout(() => {
        self.$refs['edit-group-modal'].show()
      }, 0)
    },
    deleteDevicesGroup(obj) {
      this.id = obj.id
      confirmDeletion().then(async result => {
        if (result.value) {
          this.groupDelete(this.id)
        }
      })
    },
    async groupDelete(id) {
      try {
        await this.$axios.delete(`groups/${id}`)
        showToast('Delete Group', 'Device group has been deleted successfully!')
        await this.getAllGroups()
        await this.$emit('getAllDevicesUpdated')
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Delete Group', message.toString(), 'danger')
        }
      }
    },
    // deleteDeviceFromGroup(deviceId, deviceGroupId) {
    //   const data = {
    //     deviceId,
    //     deviceGroupId,
    //   }
    //   Vue.set(this.spinners, `${deviceId}${deviceGroupId}`, true)
    //   return this.$axios
    //     .delete('/device-group', { data })
    //     .then(async () => {
    //       await this.getAllDevices()
    //       await this.getAllGroups()
    //       Vue.set(this.spinners, `${deviceId}${deviceGroupId}`, false)
    //     }).catch(error => this.$swal(error.message)).finally(() => Vue.set(this.spinners, `${deviceId}${deviceGroupId}`, false))
    // },
  },
}
</script>

<style>
.position {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f;
  border-radius: 25px;
  margin: auto;
  display: inline-block;
  padding: 5px 15px;
}
.switch {
  border-radius: 20px;
  float: right;
  color: #FFF3E8;
}
.statuss {
  color: #FF9F43;
  font-weight: 600;
  font-family: "Montserrat";
  border-radius: 25px;
  margin-left: 10px;
  display: inline-block;
  padding: 5px 15px;
  background-color: #FFF3E8;
}
body {
  margin: 0;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #1f58b5 ;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #1f58b5 ;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

</style>
